import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Pipy comes with a built-in visual Web development environment, including an editor and graphical aided design tools to simplify script development and debugging.`}</p>
    <h2 {...{
      "id": "start-admin-console"
    }}>{`Start Admin Console`}</h2>
    <p>{`Starting console is pretty straight-forward, as introduced in `}<a parentName="p" {...{
        "href": "/operating/cli/"
      }}>{`Pipy CLI`}</a>{` you can configure listening port to your specified needs by passing the `}<inlineCode parentName="p">{`--admin-port`}</inlineCode>{` command line argument.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ pipy --admin-port=6060 tutorial/03-proxy/proxy.js
2022-02-16 14:44:22.042 [INF] [config]
2022-02-16 14:44:22.042 [INF] [config] Module /proxy.js
2022-02-16 14:44:22.042 [INF] [config] ================
2022-02-16 14:44:22.042 [INF] [config]
2022-02-16 14:44:22.042 [INF] [config]  [Listen on 8000 at 0.0.0.0]
2022-02-16 14:44:22.042 [INF] [config]  ----->|
2022-02-16 14:44:22.042 [INF] [config]        |
2022-02-16 14:44:22.042 [INF] [config]       demuxHTTP
2022-02-16 14:44:22.042 [INF] [config]        |
2022-02-16 14:44:22.042 [INF] [config]        |--> [forward]
2022-02-16 14:44:22.042 [INF] [config]              muxHTTP
2022-02-16 14:44:22.042 [INF] [config]               |
2022-02-16 14:44:22.042 [INF] [config]               |--> [connection]
2022-02-16 14:44:22.042 [INF] [config]                     connect -->|
2022-02-16 14:44:22.042 [INF] [config]                                |
2022-02-16 14:44:22.042 [INF] [config]  <-----------------------------|
2022-02-16 14:44:22.042 [INF] [config]
2022-02-16 14:44:22.043 [INF] [listener] Listening on port 8000 at 0.0.0.0
2022-02-16 14:44:22.070 [INF] [admin] Starting admin service...
2022-02-16 14:44:22.071 [INF] [listener] Listening on port 6060 at ::
`}</code></pre>
    <p>{`As displayed in console output, admin console is listening on port 6060. Open `}<a parentName="p" {...{
        "href": "http://localhost:6060"
      }}>{`http://localhost:6060`}</a>{` in your browser to access it:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/operating/console.png",
        "alt": null
      }}></img></p>
    <ol>
      <li parentName="ol">{`File system: displays the directory where the loaded script resides. You can perform operations on files`}</li>
      <li parentName="ol">{`Displays all network interfaces that are currently being listened on`}</li>
      <li parentName="ol">{`Log`}</li>
      <li parentName="ol">{`Add File`}</li>
      <li parentName="ol">{`Set selected file as an main entry script`}</li>
      <li parentName="ol">{`Delete file`}</li>
      <li parentName="ol">{`Save modifications`}</li>
      <li parentName="ol">{`Start from the selected entry script`}</li>
      <li parentName="ol">{`Stop`}</li>
      <li parentName="ol">{`Reload`}</li>
      <li parentName="ol">{`Open/Hide log console`}</li>
      <li parentName="ol">{`File list`}</li>
      <li parentName="ol">{`Script editor`}</li>
      <li parentName="ol">{`Graphical aided Design tool: Shows traffic flow`}</li>
      <li parentName="ol">{`Log panel`}</li>
    </ol>
    <h2 {...{
      "id": "script-debugging"
    }}>{`Script debugging`}</h2>
    <p>{`For script writing, please refer to `}<a parentName="p" {...{
        "href": "/reference/api/Configuration"
      }}>{`Configuration API`}</a>{` and `}<a parentName="p" {...{
        "href": "/tutorial/01-hello/"
      }}>{`Tutorials`}</a></p>
    <p><img parentName="p" {...{
        "src": "/img/docs/operating/debug.png",
        "alt": null
      }}></img></p>
    <ol>
      <li parentName="ol">{`Modify response: Return `}<inlineCode parentName="li">{`Hi!`}</inlineCode></li>
      <li parentName="ol">{`Save modifications`}</li>
      <li parentName="ol">{`Reload`}</li>
    </ol>
    <p>{`Script will listen on port `}<inlineCode parentName="p">{`8080`}</inlineCode>{` , refer to `}<a parentName="p" {...{
        "href": "/getting-started/quick-start"
      }}>{`Quick start`}</a>{` to quickly starting with learning process. Once the script is running, you can invoke below shell command to verify the changes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ curl -x POST http://localhost:8080 -d 'hello world'
Hi!
`}</code></pre>
    <p>{`At this point, script returns what we expect `}<inlineCode parentName="p">{`Hi!`}</inlineCode>{`. In addition, the modified content is displayed in the file system in real time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      